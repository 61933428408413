/* Calendar Overrides */

/* Change highlight and focus to be orange */
button.react-calendar__tile--active {
  background: #fd761c;
}

button.react-calendar__tile--active:enabled:hover,
button.react-calendar__tile--active:enabled:focus {
 background: #fd8535;
}

.react-calendar__tile--hasActive {
  background: #fd761c;
}

button.react-calendar__tile--hasActive:enabled:hover,
button.react-calendar__tile--hasActive:enabled:focus {
 background: #fd8535;
}

/* Change weekends to be black not red */
button.react-calendar__month-view__days__day--weekend {
  color: black;
}

/* Hide year change arrows */
button.react-calendar__navigation__next2-button {
  display: none;
}

button.react-calendar__navigation__prev2-button {
  display: none;
}

/* Adjust disabled styles */
button.react-calendar__tile:disabled {
  background-color: initial;
  opacity: 0.4;
}

#rescheduleHistoryLabel.MuiFormLabel-root.Mui-focused {
  color: #F96302;
  padding-top: 10px;
}

#rescheduleHistorySelect.MuiSelect-select.MuiSelect-select {
    padding-top: 10px;
}

#rescheduleHistoryLabel.MuiFormLabel-root.MuiFormLabel-filled {
  padding-top: 10px;
}